.subscribe {
    margin-top: 40px;

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  .custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
  .custom-switch .custom-control-label:before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
  }

  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .custom-switch .custom-control-input:checked~.custom-control-label:after {
    background-color: #fff;
    transform: translateX(.75rem);
  }

  .custom-switch .custom-control-label:after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}