.dropdown-toggle-no-caret::after,
.dropdown-toggle-no-caret::before {
  display: none;
}

.dropdown-toggle-no-caret {
  min-width: 24px !important;
  width: 24px !important;
  padding: 0 !important;
}

.dropdown-menu {
  margin-left: -8px;
}

.btn {
  margin-right: 3px !important;
}