.profile-edit {
  h1 {
    margin-bottom: 30px;
  }

  input[type="url"],
  input[type="text"],
  input[type="search"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    height: 30px;
    background: #ebebeb;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    border-bottom: 0;
  }

  select {
    padding: 0 8px;
    font-size: 14px;
    border-bottom: 0;
  }
  .control-label {
    color: #000000;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }

  pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-bottom-color: #ccc !important;
  }

  @media screen and (max-width: 767px) {
    .control-label {
      text-align: left;
    }
    pre {
      max-width: 92vw;
      overflow-x: scroll;
    }
  }
  .payment_form label {
    position: relative;
    color: #8898aa;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
  }
  .payment_form .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .payment_form .field {
    background: transparent;
    font-weight: 300;
    border: 0;
    color: #31325f;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
  }
  #payment-form input {
    display: inline;
    width: auto;
    text-align: center;
  }
  .payment_form #card-element {
    margin-top: 11px;
  }
}
